<template>
  <DepositComponent title="Orange Money" />
</template>

<script>
import DepositComponent from "@/components/ui/DepositComponent";

export default {
  name: "OrangeDeposit",
  components: {
    DepositComponent,
  },
};
</script>
